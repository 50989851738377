import moment from 'moment';

export function timeAndDate(date) {
    let  momentObj = moment(date * 1000)
    return momentObj.format('MMM D · h:mm:ss A');
}

//formatted Month Year
export function joinDate(date) {
    const dateObj = new Date(date * 1000)
    const month = dateObj.toLocaleString('default', {month: 'short'})
    const year = dateObj.getFullYear()

    return `${month} ${year}`
}

//formatted Month Day Year
export function simpleDate(date) {
    const dateObj = new Date(date)
    const month = dateObj.toLocaleString('default', {month: 'short'})
    const year = dateObj.getFullYear()
    const day = dateObj.getDate()

    return `${month} ${day}, ${year}`
}

//get date difference from current time in days
export function differenceInDays(date, extend) {
    let date1 = new Date(date).getTime();
    let current = new Date().getTime();

    var a = moment(date1);
    var b = moment(current);

    let diff = current - date1

    let years = Math.abs(b.diff(a, 'years'))
    let months = Math.abs(b.diff(a, 'months'))
    let weeks = Math.abs(b.diff(a, 'weeks'))
    let days = Math.abs(b.diff(a, 'days'))
    let hours = Math.abs(b.diff(a, 'hours'))
    let minutes = Math.abs(b.diff(a, 'minutes'))
    let seconds = Math.abs(b.diff(a, 'seconds'))

    if (years !== 0) {
        return `${years}${extend ? ' years' : 'y'}`
    }
    else if (months !== 0) {
        return `${months}${extend ? ' months' : 'mo'}`
    }
    else if (weeks !== 0) {
        return `${weeks}${extend ? ' weeks' : 'w'}`
    }
    else if (days !== 0) {
        return `${days}${extend ? ' days' : 'd'}`
    }
    else if (hours !== 0) {
        return `${hours}${extend ? ' hours' : 'h'}`
    }
    else if (minutes !== 0) {
        return `${minutes}${extend ? ' minutes' : 'm'}`
    }
    else {
        return `${seconds}${extend ? ' seconds' : 's'}`
    }
}

export function differenceInDaysClose(start, end) {
    let start_time = new Date(start).getTime();
    let end_time = new Date(end).getTime();

    var a = moment(start_time);
    var b = moment(end_time);

    let years = Math.abs(b.diff(a, 'years'))
    let months = Math.abs(b.diff(a, 'months'))
    let weeks = Math.abs(b.diff(a, 'weeks'))
    let days = Math.abs(b.diff(a, 'days'))
    let hours = Math.abs(b.diff(a, 'hours'))
    let minutes = Math.abs(b.diff(a, 'minutes'))
    let seconds = Math.abs(b.diff(a, 'seconds'))

    if (years !== 0) {
        return `${years}y`
    }
    else if (months !== 0) {
        return `${months}mo`
    }
    else if (weeks !== 0) {
        return `${weeks}w`
    }
    else if (days !== 0) {
        return `${days}d`
    }
    else if (hours !== 0) {
        return `${hours}h`
    }
    else if (minutes !== 0) {
        return `${minutes}m`
    }
    else {
        return `${seconds}s`
    }
}

export function convertSeconds(seconds, extend) {
    const oneMinute = 60;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const oneYear = oneDay * 365.25; // Account for leap years

    if (seconds >= oneYear) {
        return Math.floor(seconds / oneYear) + (extend ? ' years' : 'y');
    } else if (seconds >= oneDay) {
        return Math.floor(seconds / oneDay) + (extend ? ' days' : 'd');
    } else if (seconds >= oneHour) {
        return Math.floor(seconds / oneHour) + (extend ? ' hours' : 'h');
    } else if (seconds >= oneMinute) {
        return Math.floor(seconds / oneMinute) + (extend ? ' minutes' : 'm');
    } else {
        return Math.floor(seconds) + (extend ? ' seconds' : 's');
    }
}

export function currentOptionDate() {
    return moment().format('YYYY/MM/DD');
}