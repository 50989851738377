import { useContext, useEffect, useState } from 'react';

import { Button, Flex, Modal, ModalBody, Spacer, Text, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Divider, Input, Textarea, VStack, Avatar, Switch, Icon, Tabs, TabList, Tab, TabPanels, TabPanel, SkeletonText } from "@chakra-ui/react"
import { GlobalContext, AuthContext } from "../App.js"
import ResizeTextarea from "react-textarea-autosize";
import { FiInfo } from 'react-icons/fi';
import ImageUploading from "react-images-uploading";
import { auth } from '../config/firebaseClient';
import { sendEmailVerification, sendPasswordResetEmail, signOut } from "firebase/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { differenceInDays } from '../util/DateUtils.js';
import { useNavigate } from 'react-router-dom';
import { BsStripe } from "react-icons/bs";
import { getLocal } from '../util/GetLocal.js';

const ProfileSettings = ({ isSmallScreen, isOpen, setIsOpen }) => {

    const [passwordResetLoading, setPasswordResetLoading] = useState(false)
    const [stripeDashboardLoading, setStripeDashboardLoading] = useState(false)
    const [stripeConnectDashboardLoading, setStripeConnectDashboardLoading] = useState(false)
    const [stripeOnboardingLoading, setStripeOnboardingLoading] = useState(false)

    const [likeNotifyLoading, setLikeNotifyLoading] = useState(false)
    const [followNotifyLoading, setFollowNotifyLoading] = useState(false)
    const [subscriptionTabLoading, setSubscriptionTabLoading] = useState(true)
    const [replyLoading, setReplyLoading] = useState(false)

    const [globalData, setGlobalData] = useContext(GlobalContext)
    const [authData, setAuthData] = useContext(AuthContext)

    const [descriptionLoading, setDescriptionLoading] = useState(false)
    const [editDescription, setEditDescription] = useState(globalData.userInfo[authData.firebaseuser.displayName].description)

    const [priceLoading, setPriceLoading] = useState(false)
    const [editPrice, setEditPrice] = useState(globalData.userInfo[authData.firebaseuser.displayName].subscription_price)

    const [emailLoading, setEmailLoading] = useState(false)
    const [editEmail, setEditEmail] = useState(authData.firebaseuser?.email)
    const [emailVerifyLoading, setEmailVerifyLoading] = useState(false)

    const [link, setLink] = useState(globalData.userInfo[authData.firebaseuser.displayName].desc_link || "")
    const [linkLoading, setLinkLoading] = useState(false)

    const [avatarLoading, setAvatarLoading] = useState(false)
    const [newImage, setNewImage] = useState([])
    const onChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setNewImage(imageList);
    };

    const [emailReqId, setEmailReqId] = useState(null)
    const [code, setCode] = useState("")

    const [connectState, setConnectState] = useState("NOT_STARTED")
    const [connectText, setConnectText] = useState("")

    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            setEditPrice(globalData.userInfo[authData.firebaseuser.displayName].subscription_price)
            setEditDescription(globalData.userInfo[authData.firebaseuser.displayName].description)
            setLink(globalData.userInfo[authData.firebaseuser.displayName].desc_link || "")
            setEditEmail(authData.firebaseuser?.email)
            setNewImage([])
            setEmailReqId(null)
            setCode("")
            //fetchData()
        }

        const getConnectVerifyState = async () => {
            setSubscriptionTabLoading(true)
            let response;
            try {
                response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/checkstripeconnect`,
                    {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error("Failed to retrieve connect status", {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setSubscriptionTabLoading(false)
                return
            }
            if (!response.ok) {
                const error = await response.json()
                console.log(error)
                toast.error(error.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setSubscriptionTabLoading(false)
                return
            }
            else {
                const data = await response.json()
                let text = "Connect and verify with Stripe to start earning when users subscribe to your page. After connecting with Stripe, you will be able to set up and update your subscription price and create posts/predictions only your subscribers will be able to view. Note: There is a 20% platform fee on all subscriptions."
                if (data.state === "ERROR") {
                    text = "Failed to retrieve verification status with Stripe, please try reloading the page."
                }
                else if (data.state === "UNVERIFIED") {
                    text = "Your account is awaiting verification. Complete the onboarding process with Stripe to unlock the ability to monetize your account and earn through subscriptions."
                }
                else if (data.state === "VERIFIED") {
                    text = "Access your available balance, see upcoming payouts, and track your earnings in real time through a fully integrated Stripe dashboard."
                }
                setConnectState(data.state)
                setConnectText(text)
                setSubscriptionTabLoading(false)
            }
        }


        if (isOpen) {
            getConnectVerifyState()
        }
        
    }, [isOpen])

    const updateDescription = async () => {
        setDescriptionLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updatedesc`,
            {
                method: "PATCH",
                headers: {
                    "ngrok-skip-browser-warning": "any",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    access_token: await getLocal(authData.firebaseuser)
                },
                body: JSON.stringify({
                    description: editDescription
                })

            })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to update description, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setDescriptionLoading(false)
            return
        }
        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setDescriptionLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()

            let newUserInfo = globalData.userInfo
            newUserInfo[authData.firebaseuser.displayName].description = editDescription
            setGlobalData({ ...globalData, userInfo: newUserInfo })
            toast.success(data.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        setDescriptionLoading(false)
    }

    const updateLink = async () => {
        setLinkLoading(true)
        if (link.length > 16) {
            toast.error("Link must be less than 16 characters", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLinkLoading(false)
            return
        }
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updatelink`,
            {
                method: "PATCH",
                headers: {
                    "ngrok-skip-browser-warning": "any",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    access_token: await getLocal(authData.firebaseuser)
                },
                body: JSON.stringify({
                    link: link
                })
            })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to update twitter/X, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLinkLoading(false)
            return
        }
        
        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setLinkLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()

            let newUserInfo = globalData.userInfo
            newUserInfo[authData.firebaseuser.displayName].desc_link = link
            setGlobalData({ ...globalData, userInfo: newUserInfo })

            toast.success(data.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        setLinkLoading(false)
    }

    const updatePrice = async () => {
        setPriceLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updateprice`,
            {
                method: "POST",
                headers: {
                    "ngrok-skip-browser-warning": "any",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    access_token: await getLocal(authData.firebaseuser, true)
                },
                body: JSON.stringify({
                    new_price: editPrice
                })

            })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to update price, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setPriceLoading(false)
            return
        }
        
        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setPriceLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)

            if (error.state === "VERIFY") {
                toast.error(error.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                sendEmailVerification(authData.firebaseuser).then(() => {
                    toast.info("Sending email verification...", {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }).catch((error) => {
                    const errorCode = error.code;
                    console.error(error)
                    toast.error(`Failed to send email verification`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                });
            }
            else if (error.state === "TIME") {
                toast.error(`Unable to change subscription price, please wait ${differenceInDays(error.timeToWait * 1000, true)}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
            }
            else {
                toast.error(error.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
            }
        }
        else {
            const data = await response.json()

            let newUserInfo = globalData.userInfo
            newUserInfo[authData.firebaseuser.displayName].subscription_price = data.newPrice
            setGlobalData({ ...globalData, userInfo: newUserInfo })
            setEditPrice(data.newPrice)

            toast.success(data.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        setPriceLoading(false)
    }

    const uploadAvatar = async (isClear) => {
        if (!isClear && newImage.length === 0) {
            toast.error("Please upload an image", {
                autoClose: 2000,
                hideProgressBar: true
            })
            return
        }
        setAvatarLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/profilepic`,
            {
                method: "POST",
                headers: {
                    "ngrok-skip-browser-warning": "any",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    access_token: await getLocal(authData.firebaseuser)
                },
                body: JSON.stringify({
                    file: isClear ? "empty" : newImage[0].data_url,
                    type: isClear ? "empty" : newImage[0].file.type,
                    clear: isClear
                })
            })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to upload avatar, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setAvatarLoading(false)
            return
        }
        
        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setAvatarLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            toast.success(data.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            const tmpFirebaseUser = authData.firebaseuser
            tmpFirebaseUser.photoURL = data.url
            setAuthData({ ...authData, firebaseuser: tmpFirebaseUser })
            setNewImage([])
        }
        setAvatarLoading(false)
    }

    const sendPasswordReset = async () => {
        setPasswordResetLoading(true)
        sendPasswordResetEmail(auth, authData.firebaseuser.email)
            .then(() => {
                toast.info(`Instructions to reset password sent to ${authData.firebaseuser.email}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setPasswordResetLoading(false)
            })
            .catch((error) => {
                const errorCode = error.code;
                toast.error(`${error.message.replaceAll("Firebase: ", "")}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setPasswordResetLoading(false)
            });
    }

    const updateFollowNotif = async (check) => {
        setFollowNotifyLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updatefollownotify`,
                {
                    method: "PATCH",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        follownotify: check
                    })

                })
        }
        catch (e) {
            console.error(e)

            toast.error("Failed to update notification setting", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setFollowNotifyLoading(false)
            return
        }


        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setFollowNotifyLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()

            let newUserInfo = globalData.userInfo
            newUserInfo[authData.firebaseuser.displayName].follow_notification = data.notify
            setGlobalData({ ...globalData, userInfo: newUserInfo })

            toast.info(data.message, {
                autoClose: 1000,
                hideProgressBar: true
            })

        }
        setFollowNotifyLoading(false)
    }

    const updateLikeNotif = async (check) => {
        setLikeNotifyLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updatelikenotify`,
                {
                    method: "PATCH",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        likenotify: check
                    })

                })
        }
        catch (e) {
            console.error(e)

            toast.error("Failed to update notification setting", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setLikeNotifyLoading(false)
            return
        }


        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setLikeNotifyLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)

            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()

            let newUserInfo = globalData.userInfo
            newUserInfo[authData.firebaseuser.displayName].like_notification = check
            setGlobalData({ ...globalData, userInfo: newUserInfo })

            toast.info(data.message, {
                autoClose: 1000,
                hideProgressBar: true
            })

        }
        setLikeNotifyLoading(false)
    }

    const updateReplyNotify = async (check) => {
        setReplyLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updatereplynotify`,
                {
                    method: "PATCH",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        replynotify: check
                    })

                })
        }
        catch (e) {
            console.error(e)

            toast.error("Failed to update notification setting", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setReplyLoading(false)
            return
        }


        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setReplyLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)

            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()

            let newUserInfo = globalData.userInfo
            newUserInfo[authData.firebaseuser.displayName].reply_notification = check
            setGlobalData({ ...globalData, userInfo: newUserInfo })

            toast.info(data.message, {
                autoClose: 1000,
                hideProgressBar: true
            })

        }
        setReplyLoading(false)
    }

    const stripePortalPage = async () => {
        setStripeDashboardLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/stripeportal`,
                {
                    method: "GET",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrive Stripe Billing Dashboard", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setStripeDashboardLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setStripeDashboardLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)

            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            window.open(data.url, "_blank", "noreferrer");

        }
        setStripeDashboardLoading(false)
    }

    const updateEmailReq = async () => {
        setEmailLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updateemailrequest`,
                {
                    method: "POST",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        email: editEmail
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to update email", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setEmailLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setEmailLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)

            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            setEmailReqId(data.request)
            toast.info(data.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        setEmailLoading(false)
    }

    const updateEmail = async () => {
        setEmailVerifyLoading(true)
        if (code.length <= 0) {
            toast.error("Invalid verification code", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setEmailVerifyLoading(false)
            return
        }

        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updateemailverify`,
                {
                    method: "POST",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        code: code,
                        requestID: emailReqId
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to update email", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setEmailVerifyLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setEmailVerifyLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)

            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            toast.info(data.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            signOut(auth).then(() => {
                toast.success("Please sign in again with updated email", {
                    autoClose: 3000,
                    hideProgressBar: true
                })
                navigate('/signin')

            }).catch((error) => {
                console.error(error)
                toast.error("Error when signing out, please try again", {
                    autoClose: 2000,
                    hideProgressBar: true
                })
            });
        }
        setEmailVerifyLoading(false)
    }

    const stripeConnectOnBoardPage = async () => {
        setStripeOnboardingLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/connectwithstripe`,
                {
                    method: "POST",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser, true)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrive Stripe Billing Dashboard", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setStripeOnboardingLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setStripeOnboardingLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)
            if (error.state === "VERIFY") {
                toast.error(error.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                sendEmailVerification(authData.firebaseuser).then(() => {
                    toast.info("Sending email verification...", {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }).catch((error) => {
                    const errorCode = error.code;
                    console.error(error)
                    toast.error(`Failed to send email verification`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                });
            }
            else {
                toast.error(error.message, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
            }
        }
        else {
            const data = await response.json()
            window.open(data.url, "_self");

        }
        setStripeOnboardingLoading(false)
    }

    const stripeConnectDashboard = async () => {
        setStripeConnectDashboardLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/stripeconnectdashboard`,
                {
                    method: "GET",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrive Stripe Billing Dashboard", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setStripeConnectDashboardLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setStripeConnectDashboardLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)

            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            window.open(data.url, "_blank", "noreferrer");
        }
        setStripeConnectDashboardLoading(false)
    }

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={isSmallScreen ? 'full' : 'xl'} autoFocus={false}>
            <ModalOverlay />
            <ModalContent bg='#1C1C1C' boxShadow='lg'>
                <ModalHeader fontFamily="Futura">Edit Profile</ModalHeader>
                <ModalBody>
                    <>
                        <Divider mb={4} />
                        {
                            <Flex fontFamily="Futura" width='100%' flexDirection="column" >
                                <VStack alignItems='center' mb={4} >
                                    <ImageUploading
                                        value={newImage}
                                        onChange={onChange}
                                        dataURLKey="data_url"
                                        acceptType={["jpeg", "jpg", "png"]}
                                        maxFileSize={1000000}
                                        onError={(e) => {
                                            let message = "Error in uploading image"
                                            if (e.maxFileSize) {
                                                message = "Error in uploading image, file size exceeds limit of 1MB"
                                            }
                                            else if (e.acceptType) {
                                                message = "Error in uploading image, file type not supported. Supported types include jpeg, jpg & png"
                                            }
                                            toast.error(message, {
                                                autoClose: 3000,
                                                hideProgressBar: true
                                            })
                                        }}

                                    >
                                        {({
                                            imageList,
                                            errors,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps
                                        }) => (
                                            // write your building UI
                                            <>
                                                <Avatar
                                                    _hover={{ cursor: "pointer" }}
                                                    onClick={onImageUpload}
                                                    isDisabled={avatarLoading}
                                                    src={imageList && imageList[0] ? imageList[0].data_url : authData.firebaseuser?.photoURL}
                                                    style={isDragging ? { opacity: 0.25 } : null}
                                                    {...dragProps}
                                                    bg=""
                                                    color=""
                                                    borderRadius='full' boxSize='150px'
                                                >
                                                </Avatar>
                                            </>
                                        )}
                                    </ImageUploading>
                                    <Flex>
                                        <Button borderRadius={10} mr={2} isLoading={avatarLoading} color='#949FFA' variant='outline' size='sm' mt={2} onClick={() => uploadAvatar(false)}>Save Profile Picture</Button>
                                        <Button borderRadius={10} isLoading={avatarLoading} color='red.200' variant='outline' size='sm' mt={2} onClick={() => uploadAvatar(true)}>Clear Profile Picture</Button>
                                    </Flex>

                                </VStack>

                                <Flex direction='row' align='center' mb={2} mt={2}>
                                    <Tabs isFitted variant="enclosed" width='100%'>
                                        <TabList>
                                            <Tab as='b' _focus={{outline: "none", boxShadow: "none"}} color="">Profile</Tab>
                                            <Tab as='b' _focus={{outline: "none", boxShadow: "none"}} color="">Notifications</Tab>
                                            <Tab as='b' _focus={{outline: "none", boxShadow: "none"}} color="">Subscriptions</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel mt={4} p={0}>
                                                <Text fontSize='sm' as='b' opacity={0.85} >Edit Twitter/X</Text>
                                                <Flex alignItems="center" width="100%" mt={1} mb={2} >
                                                    <Input focusBorderColor="#949FFA" bg='#212121' borderRadius={10} isDisabled={linkLoading} fontWeight='bold' onChange={(e) => setLink(e.target.value)} value={link} />
                                                    <Spacer ml={2} />
                                                    <Button borderRadius={10} onClick={updateLink} isLoading={linkLoading} color='#949FFA' variant='ghost' size='md'>Save</Button>
                                                </Flex>

                                                <Text fontSize='sm' as='b' opacity={0.85}>Edit Description</Text>
                                                <Flex alignItems="center" width="100%" mt={1} mb={2}>
                                                    <Textarea
                                                        onChange={(e) => setEditDescription(e.target.value)}
                                                        value={editDescription}
                                                        fontWeight='bold'
                                                        minH="7rem"
                                                        as={ResizeTextarea}
                                                        isDisabled={descriptionLoading}
                                                        focusBorderColor="#949FFA" 
                                                        bg='#212121'
                                                    />
                                                    <Spacer mr={2} />
                                                    <Button borderRadius={10} color='#949FFA' variant='ghost' size='md' isLoading={descriptionLoading} onClick={updateDescription}>Save</Button>
                                                </Flex>

                                                <Text fontSize='sm' as='b' opacity={0.85}>Update Email</Text>
                                                <Flex alignItems="center" width="100%" mt={1} mb={emailReqId ? 2 : 4}>
                                                    <Input focusBorderColor="#949FFA" bg='#212121' borderRadius={10} isDisabled={emailLoading} fontWeight='bold' onChange={(e) => setEditEmail(e.target.value)} value={editEmail} />
                                                    <Spacer mr={2} />
                                                    <Button borderRadius={10} isLoading={emailLoading} onClick={updateEmailReq} color='#949FFA' variant='ghost' size='md'>Send</Button>
                                                </Flex>                                              

                                                {
                                                    emailReqId ? (
                                                        <>
                                                            <Text fontSize='sm' as='b' opacity={0.85}>Enter code sent to '{authData.firebaseuser.email}'</Text>
                                                            <Flex alignItems="center" width="100%" mt={1} mb={4}>
                                                                <Input focusBorderColor="#949FFA" bg='#212121' borderRadius={10} isDisabled={emailVerifyLoading} fontWeight='bold' onChange={(e) => setCode(e.target.value)} value={code} />
                                                                <Spacer mr={2} />
                                                                <Button borderRadius={10} isLoading={emailVerifyLoading} onClick={updateEmail} color='#949FFA' variant='ghost' size='md'>Confirm</Button>
                                                            </Flex>
                                                        </>
                                                    ) : null
                                                }

                                                <Flex direction='column'>
                                                    <Button mb={4} borderRadius={10} isLoading={passwordResetLoading} onClick={sendPasswordReset} _hover={{bg: "#949FFA"}} color='#212121' bg='#949FFA' size='sm'>Reset Password</Button>
                                                    <Text mb={2}>
                                                        Securely manage your billing information, view past invoices, and current subscription plans through the Stripe customer portal
                                                    </Text>
                                                    <Button _hover={{bg: "#949FFA"}} color='#212121' bg='#949FFA' borderRadius={10} isLoading={stripeDashboardLoading} onClick={stripePortalPage} size='sm' leftIcon={<BsStripe size={20}/>}>Stripe Portal</Button>
                                                </Flex>
                                            </TabPanel>
                                            <TabPanel mt={4} p={0}>
                                                <Flex alignItems="center" width="100%" mb={2}>
                                                    <Text fontSize="sm" as='b' textAlign="center" opacity={0.85}>Enable Follow Notifications?</Text>
                                                    <Spacer mr={2} />
                                                    <Switch size='md' isDisabled={followNotifyLoading} onChange={(e) => updateFollowNotif(e.target.checked)} isChecked={globalData.userInfo[authData.firebaseuser.displayName].follow_notification} />
                                                </Flex>

                                                <Flex alignItems="center" width="100%" mb={2}>
                                                    <Text fontSize="sm" as='b' textAlign="center" opacity={0.85}>Enable Post Upvote Notifications?</Text>
                                                    <Spacer mr={2} />
                                                    <Switch size='md' isDisabled={likeNotifyLoading} onChange={(e) => updateLikeNotif(e.target.checked)} isChecked={globalData.userInfo[authData.firebaseuser.displayName].like_notification} />
                                                </Flex>

                                                <Flex alignItems="center" width="100%">
                                                    <Text fontSize="sm" as='b' textAlign="center" opacity={0.85}>Enable Reply Notifications?</Text>
                                                    <Spacer mr={2} />
                                                    <Switch size='md' isDisabled={replyLoading} onChange={(e) => updateReplyNotify(e.target.checked)} isChecked={globalData.userInfo[authData.firebaseuser.displayName].reply_notification} />
                                                </Flex>

                                            </TabPanel>
                                            <TabPanel mt={4} p={0}>
                                                {
                                                    subscriptionTabLoading ? (
                                                        <> 
                                                            <SkeletonText mt='2' noOfLines={5} spacing='4' skeletonHeight='2' />
                                                        </>
                                                    ) : (
                                                        <> 
                                                        <Flex direction='column' mb={2}>
                                                            <Text>
                                                                {connectText}
                                                            </Text>
                                                            {
                                                                connectState === "NOT_STARTED" || connectState === "UNVERIFIED" ? (
                                                                    <Button mt={2} _hover={{bg: "#949FFA"}} color='#212121' bg='#949FFA' borderRadius={10} isLoading={stripeOnboardingLoading} onClick={stripeConnectOnBoardPage} leftIcon={<BsStripe size={20}/>} size='sm'>Connect With Stripe</Button> 
                                                                ) : (
                                                                    connectState === "VERIFIED" ? (
                                                                        <Button mt={2} _hover={{bg: "#949FFA"}} color='#212121' bg='#949FFA' borderRadius={10} isLoading={stripeConnectDashboardLoading} onClick={stripeConnectDashboard} leftIcon={<BsStripe size={20}/>} size='sm'>Stripe Dashboard</Button> 
                                                                    ) : null
                                                                )
                                                            }
                                                        </Flex>
                                                        {
                                                            connectState === "VERIFIED" ? (
                                                                <Flex mt={4} direction='column'> 
                                                                    <Text fontSize='sm' as='b' opacity={0.85}>Subscription Price (min. $9.99, max. $500)</Text>
                                                                    <Flex alignItems="center" width="100%" mt={1}>
                                                                        <Input focusBorderColor="#949FFA" bg='#212121' borderRadius={10} isDisabled={priceLoading} fontWeight='bold' onChange={(e) => setEditPrice(e.target.value)} value={editPrice} />
                                                                        <Spacer mr={2} />
                                                                        <Button borderRadius={10} isLoading={priceLoading} onClick={updatePrice} color='#949FFA' variant='ghost' size='md'>Save</Button>
                                                                    </Flex>
                                                                    <Flex direction='row' align='center' mt={2}>
                                                                        <Icon as={FiInfo} mr={2} color='blue.200' fontSize='lg' />
                                                                        <Text opacity={0.85} as='b' fontSize='xs'>Note: New subscribers will pay updated price while pre existing subscribers will continue with their current subscription price.</Text>
                                                                    </Flex>
                                                                    <Flex direction='row' align='center' mt={2}>
                                                                        <Icon as={FiInfo} mr={2} color='blue.200' fontSize='lg' />
                                                                        <Text opacity={0.85} as='b' fontSize='xs'>Note: There is a 20% platform fee on all monthly subscriptions.</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            ) : null
                                                        }
                                                        </>
                                                    )
                                                }
                                                
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </Flex>
                            </Flex>

                        }
                    </>
                </ModalBody>
                <ModalFooter fontFamily="Futura">
                    <Button borderRadius={10} bg="" color='red.200' onClick={() => setIsOpen(false)}>Exit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}

export default ProfileSettings