import SymbolChart from "./SymbolChart"
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from 'react';
import {
    Flex,
    Text,
    Box,
    Heading,
    Button,
    List,
    SkeletonCircle,
    SkeletonText,
    useMediaQuery,
    Center
} from '@chakra-ui/react';
import { ViewportList } from "react-viewport-list";
import { GlobalContext, AuthContext } from "../App.js"
import { useParams } from "react-router-dom";
import Posts from "../Profile/Posts";
import ReplyItem from "../Reply/ReplyItem"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet"
import { FiBarChart2, FiExternalLink, FiRefreshCcw } from "react-icons/fi";
import { getLocal } from "../util/GetLocal";
import TraderView from "./TraderView";

const PostPage = ({ refM }) => {
    const [loading, setLoading] = useState(true)
    const [postData, setPostData] = useState(null)
    const [moreReply, setMoreReply] = useState(false)
    const [replyLoading, setReplyLoading] = useState(true)
    const [replies, setReplies] = useState([])
    const [moreReplyLoading, setMoreReplyLoading] = useState(false)
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [isAdvanced, setIsAdvanced] = useState(false)

    const [authData, setAuthData] = useContext(AuthContext)
    const [globalData, setGlobalData] = useContext(GlobalContext)

    const navigate = useNavigate();
    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");

    const { postID } = useParams()
    const endReply = useRef(0)


    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv) {
            scrollableDiv.scrollTo(0, 0);
        }
        const getPost = async () => {
            setLoading(true)
            let response;
            try {
                response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/postbyid?postID=${postID}`,
                    {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error("Failed to get post", {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setPostData(null)
                setLoading(false)
                return
            }

            if (!response.ok) {
                const error = await response.json()
                console.error(error)
                toast.error(error.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setPostData(null)
                setLoading(false)
                return
            }
            else {
                const data = await response.json()
                setPostData(data)
                setLoading(false)

                let posts = globalData.allPosts

                //updating all other similar posts because when we fetch new post data here every where else should have the same updated post data
                for (let key in posts) {
                    for (let i = 0; i < posts[key].length; i++) {
                        if (posts[key][i].post_id === data.post.post_id) {
                            posts[key][i] = data.post
                        }

                    }
                }

            }
        }

        const getReplies = async () => {
            setReplyLoading(true)
            let response;
            try {
                response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/reply?postID=${postID}`,
                    {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error("Failed to get replies", {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setMoreReply(false)
                setReplyLoading(false)
                return
            }
            if (!response.ok) {
                const error = await response.json()
                console.error(error)
                toast.error(error.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setMoreReply(false)
                setReplyLoading(false)
                return
            }
            else {
                const data = await response.json()
                setReplies(data.replies)
                if (data.replies.length > 0) {
                    endReply.current = data.replies[data.replies.length - 1].reply_id
                }

                if (data.replies.length <= 0) {
                    setMoreReply(false)
                }
                else {
                    setMoreReply(true)
                }
                setReplyLoading(false)
            }
        }

        getPost()
        if (authData.firebaseuser) {
            getReplies()
        }
        else {
            setReplyLoading(false)
        }

    }, [authData])

    const getNextReplies = async () => {
        setMoreReplyLoading(true)
        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/reply?postID=${postID}&endreply=${endReply.current}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            setMoreReplyLoading(false)
            toast.error("Failed to get replies", {
                autoClose: 2000,
                hideProgressBar: true
            })
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setMoreReplyLoading(false)
            return
        }
        else {
            const data = await response.json()
            endReply.current = data.next

            setReplies([...replies, ...data.replies])

            if (data.replies.length <= 0) {
                setMoreReply(false)
            }
            setMoreReplyLoading(false)
        }
    }

    const deleteReply = async (reply_id) => {
        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/reply`,
                {
                    method: 'DELETE',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        reply_id: reply_id
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to delete reply", {
                autoClose: 2000,
                hideProgressBar: true
            })
            return
        }
        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.error(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                return
            }
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            return
        }
        else {
            const filteredList = replies.filter(obj => obj.reply_id !== reply_id);

            setReplies(filteredList)

            let posts = globalData.allPosts
            let postIDHasBeenUpdated = new Set()

            for (let key in posts) {
                for (let i = 0; i < posts[key].length; i++) {
                    //so we want to update the likes on the post check if current post id matches whats in globalData.allPosts and only update
                    //if that post hasn't already been updated
                    if (posts[key][i].post_id === postID && !postIDHasBeenUpdated.has(posts[key][i].post_id)) {
                        posts[key][i].reply_count--
                        postIDHasBeenUpdated.add(posts[key][i].post_id)
                    }

                }
            }
            if (!postIDHasBeenUpdated.has(postID)) { //so that when we like from post page the UI of the post from post page component gets updated
                postData.post.reply_count--
            }
            setGlobalData({ ...globalData, allPosts: posts })
            if (postData.post.reply_count <= 0) {
                setMoreReply(false)
            }
        }
    }

    const refreshReplies = async () => {
        setRefreshLoading(true)
        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/reply?postID=${postID}&includeCount=${true}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to get replies", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        else {
            const data = await response.json()

            setReplies(data.replies)
            if (data.replies.length > 0) {
                endReply.current = data.replies[data.replies.length - 1].reply_id
            }

            if (data.replies.length <= 0) {
                setMoreReply(false)
            }
            else {
                setMoreReply(true)
            }

            let posts = globalData.allPosts
            let postIDHasBeenUpdated = new Set()

            if (data.count) {
                for (let key in posts) {
                    for (let i = 0; i < posts[key].length; i++) {
                        //so we want to update the likes on the post check if current post id matches whats in globalData.allPosts and only update
                        //if that post hasn't already been updated
                        if (posts[key][i].post_id === postID && !postIDHasBeenUpdated.has(posts[key][i].post_id)) {
                            posts[key][i].reply_count = data.count
                            postIDHasBeenUpdated.add(posts[key][i].post_id)
                        }

                    }
                }
                if (!postIDHasBeenUpdated.has(postID)) { //so that when we like from post page the UI of the post from post page component gets updated
                    postData.post.reply_count = data.count
                }
                setGlobalData({ ...globalData, allPosts: posts })
            }

            setRefreshLoading(false)
        }
    }

    if (loading) {
        return (
            <Box mx={'auto'} alignItems='center' className="cardStackst" padding={5}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bloomtrades | Post</title>
                    <link rel="canonical" href={`https://www.bloomtrades.net/post/${postID}`} />
                    <meta name="description" content="Access free market predictions and turn your insights into earnings" />
                </Helmet>
                <SkeletonCircle isLoaded={!loading} size='10' />
                <SkeletonText isLoaded={!loading} mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
        )
    }

    if (!postData) {
        return (
            <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" direction='column'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bloomtrades | Post Not Found</title>
                    <link rel="canonical" href={`https://www.bloomtrades.net/post/${postID}`} />
                    <meta name="description" content="Access free market predictions and turn your insights into earnings" />
                </Helmet>
                <Heading size="md" mb={2} mt={5} fontFamily="Futura">
                    Oops! Post not found
                </Heading>
                <Text mb={4} opacity={0.85}>You've reached an unknown or deleted post :(</Text>

                <Button size='sm' borderRadius={10} width={isSmallScreen ? "100%" : "75%"} onClick={() => navigate(authData.firebaseuser ? "/feed" : "/")} bg='#949ffa' color="#1C1C1C" _hover={{ color: '#1C1C1C', bg: '#949ffa' }}>
                    Go to bloomtrades.net
                </Button>


            </Flex>
        )
    }

    return (
        <List mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" spacing={2} pt={2}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Post - {postData.post.username}</title>
                <link rel="canonical" href={`https://www.bloomtrades.net/post/${postID}`} />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            {
                postData?.post.symbol ? ( isAdvanced ? <TraderView symbol={postData.post.symbol} isAdvanced={isAdvanced}/> : <SymbolChart symbol={postData.post.symbol} fromPost={true} alertTime={postData.post.created_at}/> ) : null
            }
            {
                postData?.post ? <Posts key={postData.post.post_id} postItem={postData.post} fromPostPage={true} setReply={setReplies} replies={replies} /> : null
            }
            {
                !authData.firebaseuser ? (
                    <Flex bg='#212121' width='100%' padding={5} mb={4} flexDirection="column" borderWidth={1} borderRadius={10} _hover={{ cursor: "pointer" }} onClick={() => navigate("/signin")}>
                        <Flex direction='row' mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} >
                            <Text as='b'>Sign in to view replies&nbsp;</Text>
                            <FiExternalLink color='#949FFA' size='20' />
                        </Flex>
                    </Flex>
                ) : null
            }
            {
                authData.firebaseuser ? (
                    <Box overflowX="auto" width="100%" style={{ scrollbarWidth: 'thin', scrollbarColor: 'transparent transparent' }}>
                        <Flex direction="row" mt={1} mb={1}>
                            <Flex whiteSpace="nowrap">
                                { postData?.post.symbol ? <Button size='sm' mr={2} color='gray.200' borderRadius={10} leftIcon={<FiBarChart2 size='18' />} onClick={() => setIsAdvanced(!isAdvanced)} opacity={isAdvanced ? 1 : 0.5}>Advanced Chart</Button> : null}
                                <Button size='sm' borderRadius={10} mr={2} color="#949FFA" leftIcon={<FiRefreshCcw size='18' />} isDisabled={loading} isLoading={refreshLoading} onClick={refreshReplies}>Refresh</Button>
                            </Flex>
                        </Flex>
                    </Box>
                ) : null
            }
            {
                replyLoading ? (
                    <Box mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" mb={20} mt={5}>
                        <SkeletonText isLoaded={!replyLoading} mt='4' noOfLines={5} spacing='4' skeletonHeight='2' />
                    </Box>
                ) : null
            }
            {

                replies.length === 0 & !replyLoading & authData.firebaseuser ? (
                    <Flex bg='#212121' mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} py={5} _hover={{ opacity: 0.9, cursor: "pointer" }} borderWidth={1} borderRadius={10} mb={2}>
                        <Text as='b'>No replies</Text>
                    </Flex>
                ) : (
                        <ViewportList items={replies} initialPrerender={10}>
                            {(item, index) => (
                                <ReplyItem data={item} key={item.reply_id} deleteReply={deleteReply} />
                            )}
                        </ViewportList>
                    )

            }
            {
                authData.firebaseuser && moreReply ? <Center mb={3} pt={2}>
                    <Button borderRadius={10} isLoading={moreReplyLoading} size='sm' width="30%" onClick={getNextReplies}>Load More</Button>
                </Center> : null
            }
        </List>
    )

}
export default PostPage