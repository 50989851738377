import { Box, Flex, List, SkeletonCircle, SkeletonText, Text, VStack, Center, Button, Spacer, useMediaQuery, Divider, CircularProgress, CircularProgressLabel, Stat, StatLabel, StatNumber, StatGroup } from "@chakra-ui/react";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { GlobalContext, AuthContext } from "../App.js"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SymbolChart from "../PostPage/SymbolChart.js";
import InfiniteScroll from "react-infinite-scroll-component";
import { ViewportList } from "react-viewport-list";
import Posts from "../Profile/Posts.js";
import { FiLock, FiRefreshCcw, FiExternalLink, FiBarChart2 } from "react-icons/fi";
import { Helmet } from "react-helmet"
import { getLocal } from "../util/GetLocal.js";
import TraderView from "../PostPage/TraderView.js";

const SymbolFeed = ({ refM }) => {

    const { symbol } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const endPost = useRef(0)
    const cnt = useRef(0)

    const [authData, setAuthData] = useContext(AuthContext)
    const [globalData, setGlobalData] = useContext(GlobalContext)

    const [loading, setLoading] = useState(true)
    const [morePosts, setMorePosts] = useState(true)
    const [morePostsLoading, setMorePostsLoading] = useState(false)
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [paidPostsFilterLoading, setPaidPostsFilterLoading] = useState(false)

    const listRef = useRef(null)

    const [initIndex, setInitIndex] = useState(globalData.allPosts[`/${symbol}`] === undefined ? -1 : searchParams.get("focus") ? (isNaN(+searchParams.get("focus")) ? -1 : +searchParams.get("focus")) : -1)
    const [offset, setOffSet] = useState(globalData.allPosts[`/${symbol}`] === undefined ? 0 : searchParams.get("offset") ? (isNaN(+searchParams.get("offset")) ? 0 : (+searchParams.get("offset")) * 1) : 0)

    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");

    if (globalData.allPosts[`/${symbol}`] === undefined) {
        window.history.replaceState({}, '', `${location.pathname}`)
    }
    

    useLayoutEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv && cnt.current <= 1) {
            scrollableDiv.scrollTo(0, 0);
            cnt.current = cnt.current + 1
        }
    })


    useEffect(() => {
        setLoading(true)
        setMorePosts(true)
        setMorePostsLoading(false)
        setRefreshLoading(false)
        const getFeed = async () => {
            let response;
            try {
                response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/symbolfeed?q=${symbol}&includeSymbolStats=${true}&paidOnly=${globalData.symbolFeedSettings.paidPosts[symbol] ? true : false}`,
                    {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error("Failed to get post", {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }

            if (!response.ok) {
                const error = await response.json()
                console.log(error)
                toast.error(error.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }
            else {
                const data = await response.json()

                let ss = globalData.symbolStats
                if (data.statistics) {
                    ss[symbol] = data.statistics
                }

                let p = globalData.allPosts
                p[`/${symbol}`] = data.posts

                //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
                for (let i = 0; i < data.posts.length; i++) {
                    for (let key in p) {
                        if (key !== `/${symbol}`) { //we already update allPosts[/symbol] with the unfollowed data posts fetch
                            for (let k = 0; k < p[key].length; k++) {
                                if (p[key][k].post_id === data.posts[i].post_id) {
                                    p[key][k] = data.posts[i]
                                }
                            }
                        }
                    }
                }

                setGlobalData({ ...globalData, allPosts: p, symbolStats: ss })

                setLoading(false)
                if (data.posts.length > 0) {
                    endPost.current = data.posts[data.posts.length - 1].post_id
                }

                if (data.posts.length <= 0) {
                    setMorePosts(false)
                }

                setLoading(false)
            }
        }

        if (!globalData.allPosts[`/${symbol}`]) {
            window.history.replaceState({}, '', `${location.pathname}`)
            getFeed()
        }
        else {
            setLoading(false)
            if (globalData.allPosts[`/${symbol}`].length > 0) {
                endPost.current = globalData.allPosts[`/${symbol}`][globalData.allPosts[`/${symbol}`].length - 1].post_id
            }
            //setMorePosts(true)
        }

    }, [symbol, authData])


    const getNextPosts = async () => {
        setMorePostsLoading(true)

        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/symbolfeed?q=${symbol}&endpost=${endPost.current}&paidOnly=${globalData.symbolFeedSettings.paidPosts[symbol] ? true : false}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            setMorePostsLoading(false)
            toast.error("Failed to retrieve posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setMorePostsLoading(false)
            return
        }
        else {
            const data = await response.json()
            console.log(data)

            endPost.current = data.next

            let p = globalData.allPosts
            p[`/${symbol}`] = [...globalData.allPosts[`/${symbol}`], ...data.posts]

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== `/${symbol}`) { //we already update allPosts[/symbol] with the unfollowed data posts fetch
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            setMorePostsLoading(false)

        }
    }

    const refreshPosts = async () => {
        setRefreshLoading(true)
        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/symbolfeed?q=${symbol}&includeSymbolStats=${true}&paidOnly=${globalData.symbolFeedSettings.paidPosts[symbol] ? true : false}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to refresh feed posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        else {
            const data = await response.json()
            console.log(data)

            let p = globalData.allPosts
            p[`/${symbol}`] = data.posts

            let ss = globalData.symbolStats
            if (data.statistics) {
                ss[symbol] = data.statistics
            }

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== `/${symbol}`) { //we already update allPosts[/feed] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p, symbolStats: ss })

            setRefreshLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
    }

    const updatePaidFilter = async () => {
        let newFilter = globalData.symbolFeedSettings.paidPosts[symbol] ? true : false
        newFilter = !newFilter

        let newSymbolFeedSettings = globalData.symbolFeedSettings
        if (newFilter) {
            newSymbolFeedSettings.paidPosts[symbol] = true
        }
        else {
            delete newSymbolFeedSettings.paidPosts[symbol]
        }
        setGlobalData({ ...globalData, symbolFeedSettings: newSymbolFeedSettings })

        setPaidPostsFilterLoading(true)
        if (newFilter) {
            toast.info("Displaying subscription posts...", {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            toast.info("Displaying all posts...", {
                autoClose: 1000,
                hideProgressBar: true
            })
        }

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/symbolfeed?q=${symbol}&paidOnly=${newFilter}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to refresh feed posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            newSymbolFeedSettings.paidPosts[symbol] = !newSymbolFeedSettings.paidPosts[symbol]
            setGlobalData({ ...globalData, symbolFeedSettings: newSymbolFeedSettings })
            setPaidPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            newSymbolFeedSettings.paidPosts[symbol] = !newSymbolFeedSettings.paidPosts[symbol]
            setGlobalData({ ...globalData, symbolFeedSettings: newSymbolFeedSettings })
            setPaidPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()

            let p = globalData.allPosts
            p[`/${symbol}`] = data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== `/${symbol}`) { //we already update allPosts[/feed] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            setPaidPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);
    }

    const updateAdvancedChart = () => {
        let newFilter = globalData.symbolFeedSettings.isAdvanced[symbol] ? true : false
        newFilter = !newFilter

        let newSymbolFeedSettings = globalData.symbolFeedSettings
        if (newFilter) {
            newSymbolFeedSettings.isAdvanced[symbol] = true
        }
        else {
            delete newSymbolFeedSettings.isAdvanced[symbol]
        }
        setGlobalData({ ...globalData, symbolFeedSettings: newSymbolFeedSettings })
    }

    if (loading) {
        return (
            <Box mx={'auto'} alignItems='center' className="cardStackst" padding={5}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bloomtrades | {symbol}</title>
                    <link rel="canonical" href={`https://www.bloomtrades.net/symbol/${symbol}`} />
                    <meta name="description" content="Access free market predictions and turn your insights into earnings" />
                </Helmet>
                <SkeletonCircle isLoaded={!loading} size='10' />
                <SkeletonText isLoaded={!loading} mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
        )
    }

    if (globalData.allPosts[`/${symbol}`] === undefined) {
        return (
            <VStack mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bloomtrades | {symbol}</title>
                    <link rel="canonical" href={`https://www.bloomtrades.net/symbol/${symbol}`} />
                    <meta name="description" content="Access free market predictions and turn your insights into earnings" />
                </Helmet>
                <Flex bg='#212121' width='100%' padding={5} flexDirection="row" borderWidth={1} borderRadius={10} mt={2} justifyContent='center'>
                    <Text as='b'>No predictions for '{symbol}'</Text>
                </Flex>
            </VStack>
        )
    }

    return (
        <List mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" pt={2}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | {symbol}</title>
                <link rel="canonical" href={`https://www.bloomtrades.net/symbol/${symbol}`} />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Flex>
                {/*<TraderView symbol={symbol} isAdvanced={globalData.symbolFeedSettings.isAdvanced[symbol] ? true : false}/>*/}
                {
                    globalData.symbolFeedSettings.isAdvanced[symbol] ? <TraderView symbol={symbol} isAdvanced={globalData.symbolFeedSettings.isAdvanced[symbol] ? true : false}/> : 
                    <SymbolChart symbol={symbol}/>
                }
            </Flex>

            <Flex direction='row' mb={3} mt={3}>
                <Button size='sm' mr={2} color='gray.200' borderRadius={10} leftIcon={<FiBarChart2 size='18' />} onClick={updateAdvancedChart} opacity={globalData.symbolFeedSettings.isAdvanced[symbol] ? 1 : 0.5}>Advanced Chart</Button>
                <Button size='sm' borderRadius={10} mr={2} color="#949FFA" leftIcon={<FiRefreshCcw size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading} isLoading={refreshLoading} onClick={refreshPosts}>Refresh</Button>
                <Button size='sm' mr={2} color='red.200' borderRadius={10} leftIcon={<FiLock size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading} isLoading={paidPostsFilterLoading} onClick={updatePaidFilter} opacity={globalData.symbolFeedSettings.paidPosts[symbol] ? 1 : 0.5}>Paid</Button>
            </Flex>
            {
                !authData.firebaseuser ? (
                    <Link to={`/signin`} >
                        <VStack mx={'auto'} alignItems='center' fontFamily="Futura" mb={2} _hover={{ cursor: "pointer" }}>
                            <Flex bg='#212121' width='100%' padding={5} flexDirection="column" borderWidth={1} borderRadius={10}>
                                <Flex direction='row' mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} >
                                    <Text as='b'>Sign in to view sentiment&nbsp;</Text>
                                    <FiExternalLink color='#949FFA' size='20' />
                                </Flex>
                            </Flex>
                        </VStack>
                    </Link>
                ) : (
                <VStack mx={'auto'} alignItems='center' fontFamily="Futura" mb={2}>
                    <Flex bg='#212121' width='100%' padding={5} flexDirection="column" borderWidth={1} borderRadius={10}>
                        <Text as='b' fontSize='xl'>Sentiment</Text>
                        <Divider mt={2} mb={2} />
                        {
                            globalData.symbolStats[symbol] === undefined ? (
                                <Flex mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} pt={2} >
                                    <Text as='b'>No statistics for '{symbol}'</Text>
                                </Flex>
                            ) : (<>
                            <StatGroup mt={2}>
                                <Stat>
                                    <StatLabel fontWeight='700'>Bullish</StatLabel>
                                    <StatNumber color='green.200'>{isSmallScreen ? Math.round(parseFloat(globalData.symbolStats[symbol].total_bullish)) : globalData.symbolStats[symbol].total_bullish}%</StatNumber>
                                </Stat>
                                <Spacer />
                                <Stat>
                                    <StatLabel fontWeight='700' width='100%'>Bearish</StatLabel>
                                    <StatNumber color='red.200'>{isSmallScreen ? Math.round(parseFloat(globalData.symbolStats[symbol].total_bearish)) : globalData.symbolStats[symbol].total_bearish}%</StatNumber>
                                </Stat>
                                <Spacer />
                                <Stat>
                                    <StatLabel fontWeight='700'>Wins</StatLabel>
                                    <StatNumber color='green.200'>{isSmallScreen ? Math.round(parseFloat(globalData.symbolStats[symbol].total_wins)) : globalData.symbolStats[symbol].total_wins}%</StatNumber>
                                </Stat>
                                <Spacer/>
                                <Stat>
                                    <StatLabel fontWeight='700'>Losses</StatLabel>
                                    <StatNumber color='red.200'>{isSmallScreen ? Math.round(parseFloat(globalData.symbolStats[symbol].total_losses)) : globalData.symbolStats[symbol].total_losses}%</StatNumber>
                                </Stat>      
                            </StatGroup>
                            </>
                                )
                        }


                    </Flex>
                </VStack>
                )
            }

            {

                globalData.allPosts[`/${symbol}`].length === 0 ? (
                    <Flex bg='#212121' mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} py={5} _hover={{ opacity: 0.9, cursor: "pointer" }} borderWidth={1} borderRadius={10} mb={5}>
                        <Text as='b'>No predictions for '{symbol}'</Text>
                    </Flex>
                )
                    : (
                        <>
                            <InfiniteScroll
                                scrollableTarget={"scrollableDiv"}
                                dataLength={globalData.allPosts[`/${symbol}`].length}
                                onScroll={(e) => {
                                    window.history.replaceState({}, '', `${location.pathname}?focus=${listRef.current.getScrollPosition().index}&offset=${listRef.current.getScrollPosition().offset}`)
                                }}
                                hasMore={false}
                                loader={<Flex ></Flex>}>
                                {
                                    <ViewportList ref={listRef} viewportRef={refM} items={globalData.allPosts[`/${symbol}`] || []} initialPrerender={10} initialIndex={initIndex} initialOffset={offset}>
                                        {(item, index) => (
                                            <Posts key={item.post_id} postItem={item} />
                                        )}
                                    </ViewportList>
                                }
                            </InfiniteScroll>
                            {
                                morePosts ? <Center mb={3} mt={2}>
                                    <Button borderRadius={10} isLoading={morePostsLoading} size='sm' width="30%" onClick={getNextPosts}>Load More</Button>
                                </Center> : null
                            }

                        </>

                    )


            }
        </List>


    )
}

export default SymbolFeed