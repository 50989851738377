import { useEffect, useState, useContext, useRef } from 'react';
import {
    Flex,
    List,
    Button,
    Text,
    Spinner,
    Box,
    SkeletonText,
    Center,
} from '@chakra-ui/react';
import { GlobalContext, AuthContext } from "../App.js"

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet"
import InfiniteScroll from 'react-infinite-scroll-component';
import Posts from "../Profile/Posts"
import { ViewportList } from "react-viewport-list";
import { FiEye, FiGlobe, FiLock, FiRefreshCcw, FiTrendingUp, FiUsers } from 'react-icons/fi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLocal } from '../util/GetLocal.js';

const Feed = ({ refM }) => {
    const [authData, setAuthData] = useContext(AuthContext)
    const [globalData, setGlobalData] = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [paidPostsFilterLoading, setPaidPostsFilterLoading] = useState(false)
    const [predictionPostsFilterLoading, setPredictionPostsFilterLoading] = useState(false)
    const [openPostsFilterLoading, setOpenPostsFilterLoading] = useState(false)
    const [globalFeedFilterLoading, setGlobalFeedFilterLoading] = useState(false)


    const [morePostsLoading, setMorePostsLoading] = useState(false)
    const [morePosts, setMorePosts] = useState(true)

    const [feed, setFeed] = useState([])

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const listRef = useRef(null)

    const [initIndex, setInitIndex] = useState(globalData.allPosts["/feed"] === undefined ? -1 : searchParams.get("focus") ? (isNaN(+searchParams.get("focus")) ? -1 : +searchParams.get("focus")) : -1)
    const [offset, setOffSet] = useState(globalData.allPosts["/feed"] === undefined ? 0 : searchParams.get("offset") ? (isNaN(+searchParams.get("offset")) ? 0 : (+searchParams.get("offset")) * 1) : 0)

    if (globalData.allPosts["/feed"] === undefined) {
        window.history.replaceState({}, '', `${location.pathname}`)
    }

    const endPost = useRef(0)


    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv) {
          scrollableDiv.scrollTo(0, 0);
        }
        const getFeed = async () => {
            setLoading(true)
            let response;
            try {
                response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/${globalData.feedSettings.isGlobalFeed ? "global" : ""}feed?paidOnly=${globalData.feedSettings.paidPosts}&predictionOnly=${globalData.feedSettings.predictionPosts}&openPosts=${globalData.feedSettings.openPosts}`,
                    {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                let p = globalData.allPosts
                p["/feed"] = []

                setGlobalData({ ...globalData, allPosts: p })

                toast.error("Failed to get posts for feed.", {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }

            if (!response.ok) {
                const error = await response.json()
                console.log(error)
                toast.error(error.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }
            else {
                const data = await response.json()
                setFeed(data.posts)

                let p = globalData.allPosts
                p["/feed"] = data.posts

                //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
                for (let i = 0; i < data.posts.length; i++) {
                    for (let key in p) {
                        if (key !== "/feed") { //we already update allPosts[/feed] with the unfollowed data posts
                            for (let k = 0; k < p[key].length; k++) {
                                if (p[key][k].post_id === data.posts[i].post_id) {
                                    p[key][k] = data.posts[i]
                                }
                            }
                        }
                    }
                }

                setGlobalData({ ...globalData, allPosts: p })

                setLoading(false)
                if (data.posts.length > 0) {
                    endPost.current = data.posts[data.posts.length - 1].post_id
                }

                if (data.posts.length <= 0) {
                    setMorePosts(false)
                }

            }
        }

        if (!authData.firebaseuser) {
            toast.error("Must be signed in to see feed", {
                autoClose: 2000,
                hideProgressBar: true
            })
            navigate("/signin")
            return
        }

        if (!globalData.allPosts["/feed"]) {
            window.history.replaceState({}, '', `${location.pathname}`)
            getFeed()
        }
        else {
            setLoading(false)
            if (globalData.allPosts["/feed"].length > 0) {
                endPost.current = globalData.allPosts["/feed"][globalData.allPosts["/feed"].length - 1].post_id
            }
            setMorePosts(true)
        }

        //for when we disable an account when any of these actions are pressed it will keep them as loading which we don't want
        setRefreshLoading(false)
        setMorePostsLoading(false)

    }, [authData])

    const getNextPosts = async () => {
        setMorePostsLoading(true)

        let url = `${process.env.REACT_APP_HOST}/api/v1/${globalData.feedSettings.isGlobalFeed ? "global" : ""}feed?endpost=${endPost.current}&paidOnly=${globalData.feedSettings.paidPosts}&predictionOnly=${globalData.feedSettings.predictionPosts}&openPosts=${globalData.feedSettings.openPosts}`
        let response
        try {
            response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            setMorePostsLoading(false)
            toast.error("Failed to retrieve posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            return
        }
        if (!response.ok) {
            const error1 = await response.json()
            console.log(error1)
            toast.error(error1.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setMorePostsLoading(false)
            return
        }
        else {
            const data = await response.json()

            endPost.current = data.next

            let p = globalData.allPosts
            p["/feed"] = [...globalData.allPosts["/feed"], ...data.posts]

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== "/feed") { //we already update allPosts[/feed] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            setMorePostsLoading(false)

        }
    }

    const refreshPosts = async () => {
        setRefreshLoading(true)
        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/${globalData.feedSettings.isGlobalFeed ? "global" : ""}feed?paidOnly=${globalData.feedSettings.paidPosts}&predictionOnly=${globalData.feedSettings.predictionPosts}&openPosts=${globalData.feedSettings.openPosts}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to refresh feed posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        else {
            const data = await response.json()
            setFeed(data.posts)

            let p = globalData.allPosts
            p["/feed"] = data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== "/feed") { //we already update allPosts[/feed] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            setRefreshLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
    }

    const updatePaidFilter = async () => {
        let newFilter = globalData.feedSettings
        newFilter.paidPosts = !newFilter.paidPosts
        setGlobalData({ ...globalData, feedSettings: newFilter })

        setPaidPostsFilterLoading(true)

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/${newFilter.isGlobalFeed ? "global" : ""}feed?paidOnly=${newFilter.paidPosts}&predictionOnly=${globalData.feedSettings.predictionPosts}&openPosts=${globalData.feedSettings.openPosts}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve feed posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.paidPosts = !newFilter.paidPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setPaidPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.paidPosts = !newFilter.paidPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setPaidPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()
            setFeed(data.posts)

            let p = globalData.allPosts
            p["/feed"] = data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== "/feed") { //we already update allPosts[/feed] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            setPaidPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);
    }

    const updatePredictionFilter = async () => {
        let newFilter = globalData.feedSettings
        newFilter.predictionPosts = !newFilter.predictionPosts
        setGlobalData({ ...globalData, feedSettings: newFilter })

        setPredictionPostsFilterLoading(true)

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/${newFilter.isGlobalFeed ? "global" : ""}feed?paidOnly=${globalData.feedSettings.paidPosts}&predictionOnly=${newFilter.predictionPosts}&openPosts=${globalData.feedSettings.openPosts}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve feed posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.predictionPosts = !newFilter.predictionPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setPredictionPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.predictionPosts = !newFilter.predictionPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setPredictionPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()
            setFeed(data.posts)

            let p = globalData.allPosts
            p["/feed"] = data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== "/feed") { //we already update allPosts[/feed] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            setPredictionPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);
    }

    const updateOpenFilter = async () => {
        setOpenPostsFilterLoading(true)

        let newFilter = globalData.feedSettings
        newFilter.openPosts = !newFilter.openPosts
        setGlobalData({ ...globalData, feedSettings: newFilter })

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/${newFilter.isGlobalFeed ? "global" : ""}feed?paidOnly=${globalData.feedSettings.paidPosts}&predictionOnly=${globalData.feedSettings.predictionPosts}&openPosts=${newFilter.openPosts}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve feed posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.openPosts = !newFilter.openPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setOpenPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.openPosts = !newFilter.openPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setOpenPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()
            setFeed(data.posts)

            let p = globalData.allPosts
            p["/feed"] = data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== "/feed") { //we already update allPosts[/feed] with the new filtered data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            setOpenPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }

        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);

        setOpenPostsFilterLoading(false)

    }

    const updateGlobalFeedFilter = async () => {
        setGlobalFeedFilterLoading(true)

        let newFilter = globalData.feedSettings
        newFilter.isGlobalFeed = !newFilter.isGlobalFeed
        setGlobalData({ ...globalData, feedSettings: newFilter })

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/${newFilter.isGlobalFeed ? "global" : ""}feed?paidOnly=${globalData.feedSettings.paidPosts}&predictionOnly=${globalData.feedSettings.predictionPosts}&openPosts=${newFilter.openPosts}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve feed posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.openPosts = !newFilter.openPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setOpenPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.openPosts = !newFilter.openPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setOpenPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()
            setFeed(data.posts)

            let p = globalData.allPosts
            p["/feed"] = data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== "/feed") { //we already update allPosts[/feed] with the new filtered data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            setOpenPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }

        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);

        setGlobalFeedFilterLoading(false)

    }

    if (loading) {
        return <Box mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" mb={20} mt={5}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Feed</title>
                <link rel="canonical" href="https://www.bloomtrades.net/feed" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <SkeletonText noOfLines={8} mt={6} spacing={4} />
        </Box>
    }

    if (globalData.allPosts["/feed"] === undefined) {
        return <Flex bg='#1C1C1C' mx={'auto'} alignItems='center' className="cardStackst" justify={'center'} pt={5}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Feed</title>
                <link rel="canonical" href="https://www.bloomtrades.net/feed" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Text as='b'>Feed could not be loaded, try refreshing page</Text>
        </Flex>
    }

    return (
        <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Feed</title>
                <link rel="canonical" href="https://www.bloomtrades.net/feed" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <List width="100%" mx={'auto'} alignItems='center' className="borderst">

                <Box overflowX="auto" width="100%" sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}>
                    <Flex direction="row" mt={3} mb={3}>
                        <Flex whiteSpace="nowrap">
                            <Button size='sm' borderRadius={10} mr={2} color="#949FFA" leftIcon={<FiRefreshCcw size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading || globalFeedFilterLoading} isLoading={refreshLoading} onClick={refreshPosts}>Refresh</Button>
                            <Button size='sm' mr={2} color='blue.200' borderRadius={10} leftIcon={globalData.feedSettings.isGlobalFeed ? <FiGlobe size='18' /> : <FiUsers size='18' /> } isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading || globalFeedFilterLoading} isLoading={globalFeedFilterLoading} onClick={updateGlobalFeedFilter}>{globalData.feedSettings.isGlobalFeed ? "Global" : "Home"} Feed</Button>
                            <Button size='sm' mr={2} color='red.200' borderRadius={10} leftIcon={<FiLock size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading || globalFeedFilterLoading} isLoading={paidPostsFilterLoading} onClick={updatePaidFilter} opacity={globalData.feedSettings.paidPosts ? 1 : 0.5}>Paid</Button>
                            <Button size='sm' borderRadius={10} mr={2} color="orange.200" leftIcon={<FiTrendingUp size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading || globalFeedFilterLoading} isLoading={predictionPostsFilterLoading} onClick={updatePredictionFilter} opacity={globalData.feedSettings.predictionPosts ? 1 : 0.5}>Prediction</Button>
                            <Button size='sm' borderRadius={10} mr={2} color="cyan.200" leftIcon={<FiEye size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading || globalFeedFilterLoading} isLoading={openPostsFilterLoading} onClick={updateOpenFilter} opacity={globalData.feedSettings.openPosts ? 1 : 0.5}>Open</Button>
                        </Flex>
                    </Flex>
                </Box>

                {

                    globalData.allPosts["/feed"].length === 0 ? (
                        <Flex bg='#212121' mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} py={5} borderWidth={1} borderRadius={10}>
                            <Text as='b'>Follow users to see their posts here</Text>
                        </Flex>
                    )
                        : (
                            <>
                                <InfiniteScroll
                                    scrollableTarget={"scrollableDiv"}
                                    dataLength={globalData.allPosts["/feed"].length}
                                    onScroll={(e) => {
                                        window.history.replaceState({}, '', `${location.pathname}?focus=${listRef.current.getScrollPosition().index}&offset=${listRef.current.getScrollPosition().offset}`)
                                    }}
                                    hasMore={false}
                                    loader={<Flex mt={5} mb={5} justify="center"> <Spinner thickness='4px' speed='1s' size='md' color='#949FFA' /> </Flex>}>
                                    {
                                        <ViewportList ref={listRef} viewportRef={refM} items={globalData.allPosts["/feed"] || []} initialIndex={initIndex} initialOffset={offset} initialPrerender={10}>
                                            {(item, index) => (
                                                <Posts key={item.post_id} postItem={item} />
                                            )}
                                        </ViewportList>
                                    }
                                </InfiniteScroll>
                                {
                                    morePosts ? <Center mb={3} mt={2}>
                                        <Button borderRadius={10} isLoading={morePostsLoading} size='sm' width="30%" onClick={getNextPosts}>Load More</Button>
                                    </Center> : null
                                }
                            </>

                        )


                }

            </List>
        </Flex>

    )
}

export default Feed;