import { useEffect, useState, useContext, useRef } from 'react';
import { loadStripe } from "@stripe/stripe-js"
import {
    Flex,
    List,
    Button,
    VStack,
    Avatar,
    Text,
    Divider,
    Spacer,
    Heading,
    Spinner,
    Box,
    useMediaQuery,
    SkeletonCircle,
    SkeletonText,
    Center,
    Tag,
    Icon,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup
} from '@chakra-ui/react';
import './profileMain.css'
import { GlobalContext, AuthContext } from "../App.js"

import { FiEye, FiLock, FiRefreshCcw, FiSettings, FiTrendingUp } from 'react-icons/fi'
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { joinDate } from "../util/DateUtils"
import InfiniteScroll from 'react-infinite-scroll-component';
import Posts from "./Posts"
import { ViewportList } from "react-viewport-list";
import SubscriptionSettings from './SubscriptionSettings';
import { formatLargeNumber } from '../util/NumberUtils';
import { Helmet } from "react-helmet"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCalendarDays, FaXTwitter } from 'react-icons/fa6';
import { BsStripe } from "react-icons/bs";
import { sendEmailVerification } from 'firebase/auth';
import { getLocal } from '../util/GetLocal';
import { MdVerified } from "react-icons/md";

const ProfileMain = ({ refM }) => {
    const { username } = useParams()

    const [loading, setLoading] = useState(true)
    const [followLoading, setFollowLoading] = useState(false)
    const [freeFollowLoading, setFreeFollowLoading] = useState(false)
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [morePostsLoading, setMorePostsLoading] = useState(false)
    const [paidPostsFilterLoading, setPaidPostsFilterLoading] = useState(false)
    const [morePosts, setMorePosts] = useState(true)
    const [predictionPostsFilterLoading, setPredictionPostsFilterLoading] = useState(false)
    const [openPostsFilterLoading, setOpenPostsFilterLoading] = useState(false)

    const [subscriptionSettingsModal, setSubscriptionSettingsModal] = useState(false)

    const [globalData, setGlobalData] = useContext(GlobalContext)
    const [authData, setAuthData] = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams();

    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");

    const navigate = useNavigate();
    const location = useLocation();

    const listRef = useRef(null)

    const [initIndex, setInitIndex] = useState(globalData.userInfo === null || globalData.userInfo[username] === undefined ? -1 : searchParams.get("focus") ? (isNaN(+searchParams.get("focus")) ? -1 : +searchParams.get("focus")) : -1)
    const [offset, setOffSet] = useState(globalData.userInfo === null || globalData.userInfo[username] === undefined ? 0 : searchParams.get("offset") ? (isNaN(+searchParams.get("offset")) ? 0 : (+searchParams.get("offset")) * 1) : 0)
    //set offset to -1000 and index to 0 if we see there is no focus or offset params in url so it goes to top automatcially even we click on link to another page with populated globaldata already
    if (globalData.userInfo === null || globalData.userInfo[username] === undefined) {
        window.history.replaceState({}, '', `${location.pathname}`)
    }

    console.log(initIndex)
    console.log(offset)
    const endPost = useRef(0)

    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv) {
          scrollableDiv.scrollTo(0, 0);
        }
        const getProfileInfo = async () => {
            setLoading(true)
            let url = `${process.env.REACT_APP_HOST}/api/v1/userbyname?username=${username}&paidOnly=${globalData.profileSettings.paidPosts[username] ? true : false}&predictionOnly=${globalData.profileSettings.predictionPosts[username] ? true : false}&openPosts=${globalData.profileSettings.openPosts[username] ? true : false}`
            let response
            try {
                response = await fetch(url,
                    {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error("Failed to find user", {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }

            if (!response.ok) {
                const error1 = await response.json()
                console.log(error1)

                toast.error(error1.message, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }
            else {
                const data = await response.json()

                let tempUserInfo = globalData.userInfo
                tempUserInfo[username] = data.data

                let p = globalData.allPosts
                p[username] = data.data.posts

                //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
                for (let i = 0; i < data.data.posts.length; i++) {
                    for (let key in p) {
                        if (key !== username) { //we already update allPosts[username] with the unfollowed data posts
                            for (let k = 0; k < p[key].length; k++) {
                                if (p[key][k].post_id === data.data.posts[i].post_id) {
                                    p[key][k] = data.data.posts[i]
                                }
                            }
                        }
                    }
                }

                setGlobalData({ ...globalData, userInfo: tempUserInfo, allPosts: p })

                setLoading(false)
                if (data.data.posts.length > 0) {
                    endPost.current = data.data.posts[data.data.posts.length - 1].post_id
                }

                if (data.data.posts.length <= 0) {
                    setMorePosts(false)
                }

            }

        }
        if (authData.firebaseuser?.displayName === username) {
            navigate("/profile")
            return
        }

        if (!globalData.userInfo[username]) {
            window.history.replaceState({}, '', `${location.pathname}`)
            getProfileInfo()
        }
        else {
            setLoading(false)
            setMorePosts(true)
            if (globalData.allPosts[username].length > 0) {
                endPost.current = globalData.allPosts[username][globalData.allPosts[username].length - 1].post_id
            }
        }

        //for when we disable an account when any of these actions are pressed it will keep them as loading which we don't want
        setFollowLoading(false)
        setRefreshLoading(false)
        setMorePostsLoading(false)

    }, [username, authData])


    const getNextPosts = async () => {
        setMorePostsLoading(true)

        let url = `${process.env.REACT_APP_HOST}/api/v1/posts?username=${username}&endpost=${endPost.current}&paidOnly=${globalData.profileSettings.paidPosts[username] ? true : false}&predictionOnly=${globalData.profileSettings.predictionPosts[username] ? true : false}&openPosts=${globalData.profileSettings.openPosts[username] ? true : false}`
        let response
        try {
            response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            setMorePostsLoading(false)

            toast.error("Failed to retrieve posts", {
                autoClose: 1000,
                hideProgressBar: true
            })
            return
        }
        if (!response.ok) {
            const error1 = await response.json()
            console.log(error1)

            toast.error(error1.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            setMorePostsLoading(false)
            return
        }
        else {
            const data = await response.json()

            endPost.current = data.next

            let p = globalData.allPosts
            p[username] = [...globalData.allPosts[username], ...data.posts]

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== username) { //we already update allPosts[username] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            endPost.current = data.next

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            setMorePostsLoading(false)

        }
    }


    const follow = async () => {
        setFollowLoading(true)
        if (!authData.firebaseuser) {
            toast.error("Must be signed in to follow", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setFollowLoading(false)
            return
        }
        let response

        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/follow`,
                {
                    method: "PUT",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser, true)
                    },
                    body: JSON.stringify({
                        uid: globalData.userInfo[username].id,
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to follow user", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setFollowLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setFollowLoading(false)
                return
            }
            const e = await response.json()
            console.log(e)

            if (e.state === "VERIFY") {
                toast.error(e.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                sendEmailVerification(authData.firebaseuser).then(() => {
                    toast.info("Sending email verification...", {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }).catch((error) => {
                    const errorCode = error.code;
                    console.error(error)
                    toast.error(`Failed to send email verification`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                });
            }
            else {
                toast.error(e.message, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
            }
            setFollowLoading(false)
            return
        }
        else {
            const data = await response.json()
            setFollowLoading(false)
            const stripe = await loadStripe("pk_live_51OikvgFXaxNMH0QbGS79B9OkbtflknHG5ORqMngbs978PtflU5ItF2Gr0jM9E8NfcOepmYr0dVb8xjNtE4fmYhf900UOJlPcJe")
            await stripe.redirectToCheckout({ sessionId: data.sessionID })

        }
        setFollowLoading(false)
    }

    const freeFollow = async () => {
        setFreeFollowLoading(true)
        if (!authData.firebaseuser) {
            toast.error("Must be signed in to follow", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setFreeFollowLoading(false)
            return
        }
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/followfree?paidOnly=${globalData.profileSettings.paidPosts[username] ? true : false}&predictionOnly=${globalData.profileSettings.predictionPosts[username] ? true : false}&openPosts=${globalData.profileSettings.openPosts[username] ? true : false}`,
                {
                    method: "PUT",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        uid: globalData.userInfo[username].id,
                        access_token: await getLocal(authData.firebaseuser)
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to follow user", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setFreeFollowLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)

            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setFreeFollowLoading(false)
            return
        }
        else {
            const data = await response.json()

            toast({
                title: data.message,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            let tempUserInfo = globalData.userInfo
            tempUserInfo[username] = data.newData

            if (tempUserInfo[authData.firebaseuser.displayName]) {
                console.log("updated followed count for auth data")
                tempUserInfo[authData.firebaseuser.displayName].followedCount = parseInt(tempUserInfo[authData.firebaseuser.displayName].followedCount) + 1
                console.log(tempUserInfo)
            }

            let p = globalData.allPosts
            p[username] = data.newData.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.newData.posts.length; i++) {
                for (let key in p) {
                    if (key !== username) { //we already update allPosts[username]/tempUserInfo[username] with the followed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.newData.posts[i].post_id) {
                                p[key][k] = data.newData.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, userInfo: tempUserInfo, allPosts: p })

            if (data.newData.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
        setFreeFollowLoading(false)

    }

    const refreshProfile = async () => {
        setRefreshLoading(true)
        let url = `${process.env.REACT_APP_HOST}/api/v1/userbyname?username=${username}&paidOnly=${globalData.profileSettings.paidPosts[username] ? true : false}&predictionOnly=${globalData.profileSettings.predictionPosts[username] ? true : false}&openPosts=${globalData.profileSettings.openPosts[username] ? true : false}`
        let response
        try {
            response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)

            toast.error("Failed to refresh posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        if (!response.ok) {
            const error1 = await response.json()
            console.log(error1)
            toast({
                title: error1.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            setRefreshLoading(false)
            return
        }
        else {
            const data = await response.json()

            let tempUserInfo = globalData.userInfo
            tempUserInfo[username] = data.data

            let p = globalData.allPosts
            p[username] = data.data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.data.posts.length; i++) {
                for (let key in p) {
                    if (key !== username) { //we already update allPosts[username] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.data.posts[i].post_id) {
                                p[key][k] = data.data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, userInfo: tempUserInfo, allPosts: p })

            setRefreshLoading(false)
            if (data.data.posts.length > 0) {
                endPost.current = data.data.posts[data.data.posts.length - 1].post_id
            }

            if (data.data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }

        }
    }

    const updatePaidFilter = async () => {
        let newFilter = globalData.profileSettings.paidPosts[username] ? true : false
        newFilter = !newFilter

        let newProfileSettings = globalData.profileSettings
        if (newFilter) {
            newProfileSettings.paidPosts[username] = true
        }
        else {
            delete newProfileSettings.paidPosts[username]
        }
        setGlobalData({ ...globalData, profileSettings: newProfileSettings })

        setPaidPostsFilterLoading(true)

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/posts?username=${username}&paidOnly=${newFilter}&predictionOnly=${globalData.profileSettings.predictionPosts[username] ? true : false}&openPosts=${globalData.profileSettings.openPosts[username] ? true : false}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve posts", {
                autoClose: 1000,
                hideProgressBar: true
            })
            newProfileSettings.paidPosts[username] = !newProfileSettings.paidPosts[username]
            setGlobalData({ ...globalData, newProfileSettings: newProfileSettings })
            setPaidPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            newProfileSettings.paidPosts[username] = !newProfileSettings.paidPosts[username]
            setGlobalData({ ...globalData, newProfileSettings: newProfileSettings })
            setPaidPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()

            let p = globalData.allPosts
            p[username] = data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== username) { //we already update allPosts[username] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            setPaidPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);
    }

    const updatePredictionFilter = async () => {
        let newFilter = globalData.profileSettings.predictionPosts[username] ? true : false
        newFilter = !newFilter

        let newProfileSettings = globalData.profileSettings
        if (newFilter) {
            newProfileSettings.predictionPosts[username] = true
        }
        else {
            delete newProfileSettings.predictionPosts[username]
        }
        setGlobalData({ ...globalData, profileSettings: newProfileSettings })

        setPredictionPostsFilterLoading(true)

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/posts?username=${username}&paidOnly=${globalData.profileSettings.paidPosts[username] ? true : false}&predictionOnly=${newFilter}&openPosts=${globalData.profileSettings.openPosts[username] ? true : false}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve posts", {
                autoClose: 1000,
                hideProgressBar: true
            })
            newProfileSettings.predictionPosts[username] = !newProfileSettings.predictionPosts[username]
            setGlobalData({ ...globalData, newProfileSettings: newProfileSettings })
            setPredictionPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            newProfileSettings.predictionPosts[username] = !newProfileSettings.predictionPosts[username]
            setGlobalData({ ...globalData, newProfileSettings: newProfileSettings })
            setPredictionPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()

            let p = globalData.allPosts
            p[username] = data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== username) { //we already update allPosts[username] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            setPredictionPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);
    }

        
    const updateOpenFilter = async () => {
        setOpenPostsFilterLoading(true)

        let newFilter = globalData.profileSettings.openPosts[username] ? true : false
        newFilter = !newFilter

        let newProfileSettings = globalData.profileSettings
        if (newFilter) {
            newProfileSettings.openPosts[username] = true
        }
        else {
            delete newProfileSettings.openPosts[username]
        }
        setGlobalData({ ...globalData, profileSettings: newProfileSettings })

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/posts?username=${username}&paidOnly=${globalData.profileSettings.paidPosts[username] ? true : false}&predictionOnly=${globalData.profileSettings.predictionPosts[username] ? true : false}&openPosts=${newFilter}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve posts", {
                autoClose: 1000,
                hideProgressBar: true
            })
            newProfileSettings.openPosts[username] = !newProfileSettings.openPosts[username]
            setGlobalData({ ...globalData, newProfileSettings: newProfileSettings })
            setOpenPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            newProfileSettings.openPosts[username] = !newProfileSettings.openPosts[username]
            setGlobalData({ ...globalData, newProfileSettings: newProfileSettings })
            setOpenPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()

            let p = globalData.allPosts
            p[username] = data.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.posts.length; i++) {
                for (let key in p) {
                    if (key !== username) { //we already update allPosts[username] with the unfollowed data posts
                        for (let k = 0; k < p[key].length; k++) {
                            if (p[key][k].post_id === data.posts[i].post_id) {
                                p[key][k] = data.posts[i]
                            }
                        }
                    }
                }
            }

            setGlobalData({ ...globalData, allPosts: p })

            setOpenPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }


        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);

        setOpenPostsFilterLoading(false)
    }

    if (loading) {
        return <Box mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" mb={20} mt={5}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | {username}</title>
                <link rel="canonical" href={`https://www.bloomtrades.net/user/${username}`} />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <SkeletonCircle isLoaded={!loading} size='150px' mx={'auto'} alignItems='center' />
            <SkeletonText isLoaded={!loading} mt='4' noOfLines={5} spacing='4' skeletonHeight='2' />
        </Box>
    }

    if (globalData.userInfo[username] === undefined) {
        return (
            <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" direction='column'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bloomtrades | Post Not Found</title>
                    <link rel="canonical" href={`https://www.bloomtrades.net/`} />
                    <meta name="description" content="Access free market predictions and turn your insights into earnings" />
                </Helmet>
                <Heading size="md" mb={2} mt={5} fontFamily="Futura">
                    Oops! Profile not found
                </Heading>
                <Text mb={4} opacity={0.85}>This profile doesn't exist, try searching for another</Text>

                <Button size='sm' _hover={{bg: '#949FFA'}} bg='#949FFA' color='#212121' borderRadius={10} width={isSmallScreen ? "100%" : "75%"} onClick={() => navigate("/search")}>
                    Search profiles
                </Button>
            </Flex>
        )
    }

    return (
        <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | {username}</title>
                <link rel="canonical" href={`https://www.bloomtrades.net/user/${username}`} />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <List width="100%" mx={'auto'} alignItems='center' className="borderst">
                <VStack alignItems='center' paddingBottom={0} paddingTop={2}>


                    {/*Modals*/}
                    {authData.firebaseuser !== null && globalData.userInfo[username].loggedUserIsFollowing ? <SubscriptionSettings setMorePosts={setMorePosts} tgtUsername={username} tgtData={globalData.userInfo[username]} isOpen={subscriptionSettingsModal} setIsOpen={(e) => setSubscriptionSettingsModal(e)} isSmallScreen={isSmallScreen} /> : null}

                    <Flex bg='#212121' width='100%' padding={5} flexDirection="column" borderWidth={1} borderRadius={10} alignItems='center'>
                        <Avatar size='2xl' size='2xl' bg='' mb={2} src={globalData.userInfo[username].avatar || ""}></Avatar>

                        <Heading fontFamily="Futura" textAlign='center' fontSize='3xl'>
                                <Flex flexDirection='row' alignItems="center">
                                    {globalData.userInfo[username].username}
                                    {
                                        globalData.userInfo[username].user_verified ? <Icon as={MdVerified} color='#949ffa' ml={0.5} boxSize={8}/> : null
                                    }
                                </Flex>
                            </Heading>

                        <Box style={{ whiteSpace: 'pre-wrap' }} textAlign='center' fontFamily='Futura' as='b' width="100%" mt={2}>
                            <Text textAlign='center' as='b' opacity={0.85}>{globalData.userInfo[username].description?.replaceAll("\n", " ")}</Text>
                        </Box>

                        <Flex flexDirection='row' width="100%" pb={2} pt={2} as='b'>
                            <Text>Followers: {formatLargeNumber(globalData.userInfo[username].followerCount)}</Text>
                            <Spacer />
                            <Text>Following: {formatLargeNumber(globalData.userInfo[username].followedCount)}</Text>
                        </Flex>

                        {
                            !globalData.userInfo[username].loggedUserIsFollowing || (globalData.userInfo[username].loggedUserIsFollowing && !globalData.userInfo[username].loggedUserFollowingData.is_paid) ? (
                                <Button mb={2} borderRadius={10} isLoading={freeFollowLoading} onClick={globalData.userInfo[username].loggedUserIsFollowing ? null : freeFollow} variant="outline" color='#949FFA' width="100%" size='sm'>
                                    {globalData.userInfo[username].loggedUserIsFollowing ? "Following for FREE" : "Follow for FREE"}
                                </Button>
                            ) : null
                        }

                        {
                            globalData.userInfo[username].subscription_price > 0 ? (
                                <Button leftIcon={<BsStripe size={18}/>} size='sm' _hover={{bg: "#949FFA"}} color='#212121' bg='#949FFA' mb={2} borderRadius={10} isLoading={followLoading} onClick={globalData.userInfo[username].loggedUserIsFollowing && globalData.userInfo[username].loggedUserFollowingData.is_paid ? null : follow} width="100%">
                                    {globalData.userInfo[username].loggedUserIsFollowing && globalData.userInfo[username].loggedUserFollowingData.is_paid ? "Subscribed" : "Subscribe"} · ${globalData.userInfo[username].loggedUserIsFollowing && globalData.userInfo[username].loggedUserFollowingData.is_paid ? globalData.userInfo[username].loggedUserFollowingData.sub_total.toLocaleString(undefined, { maximumFractionDigits: 4 }) : globalData.userInfo[username].subscription_price.toLocaleString(undefined, { maximumFractionDigits: 4 })} USD / month
                                </Button>
                            ) : null
                        }

                        <Divider mt={2} mb={4} />

                        <Flex direction='column' width='100%'>
                            {
                                globalData.userInfo[username]?.desc_link ? (
                                    <Flex direction='row' mb={2}>
                                        <Text as='b'>Twitter/X</Text>
                                        <Spacer />
                                        <a href={`https://www.twitter.com/${globalData.userInfo[username].desc_link}`} target="_blank">
                                            <Tag colorScheme='gray' as='b'>
                                                <>
                                                    <FaXTwitter size='16' />
                                                    <Text as='b' ml={2}>
                                                        {globalData.userInfo[username].desc_link}
                                                    </Text>
                                                </>
                                            </Tag>
                                        </a>
                                    </Flex>
                                ) : null
                            }
                            <Flex direction='row' mb={2}>
                                <Text as='b'>Joined</Text>
                                <Spacer />
                                <Tag colorScheme='gray'> 
                                    <>
                                        <FaCalendarDays size='14' />
                                        <Text as='b' ml={2}>{joinDate(globalData.userInfo[username]?.created_at)}</Text> 
                                    </>
                                </Tag>
                            </Flex>

                            <StatGroup mt={2}>
                                <Stat>
                                    <StatLabel fontWeight='700'>Wins</StatLabel>
                                    <StatNumber color='green.200'>{isSmallScreen ? Math.round(parseFloat(globalData.userInfo[username]?.stats.total_win_posts)) : globalData.userInfo[username]?.stats.total_win_posts || 0}%</StatNumber>
                                </Stat>
                                <Spacer />
                                <Stat>
                                    <StatLabel fontWeight='700' width='100%'>Avg&nbsp;Gain</StatLabel>
                                    <StatNumber color={(globalData.userInfo[username]?.stats.average_bullish_closed_percent || 0) > 0 ? 'green.200' : 'red.200'}>{globalData.userInfo[username]?.stats.average_bullish_closed_percent || 0}%</StatNumber>
                                </Stat>
                                <Spacer />
                                <Stat>
                                    <StatLabel fontWeight='700'>Paid</StatLabel>
                                    <StatNumber color='#949ffa'>{globalData.userInfo[username]?.stats.total_not_free_posts || 0}</StatNumber>
                                </Stat>
                                <Spacer/>
                                <Stat>
                                    <StatLabel fontWeight='700'>Predictions</StatLabel>
                                    <StatNumber color='#949ffa'>{globalData.userInfo[username]?.stats.total_closed_posts || 0}</StatNumber>
                                </Stat>      
                            </StatGroup>

                        </Flex>
                    </Flex>

                </VStack>
                <Box position='relative' overflowX="auto" width="100%" sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}>
                    <Flex direction="row" mt={3} mb={3}>
                        <Flex whiteSpace="nowrap">
                        <Button size='sm' borderRadius={10} mr={2} color="#949FFA" leftIcon={<FiRefreshCcw size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading} isLoading={refreshLoading} onClick={refreshProfile}>Refresh</Button>
                        {
                            authData.firebaseuser !== null && globalData.userInfo[username].loggedUserIsFollowing ? (
                                <Button size='sm' borderRadius={10} color="gray.200" mr={2} onClick={() => setSubscriptionSettingsModal(true)} leftIcon={<FiSettings size='18' />}>Settings</Button>
                            ) : null
                        }
                        <Button size='sm' mr={2} color='red.200' borderRadius={10} leftIcon={<FiLock size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading} isLoading={paidPostsFilterLoading} onClick={updatePaidFilter} opacity={globalData.profileSettings.paidPosts[username] ? 1 : 0.5}>Paid</Button>
                        <Button size='sm' borderRadius={10} mr={2} color="orange.200" leftIcon={<FiTrendingUp size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading} isLoading={predictionPostsFilterLoading} onClick={updatePredictionFilter} opacity={globalData.profileSettings.predictionPosts[username] ? 1 : 0.5}>Prediction</Button>
                        <Button size='sm' borderRadius={10} mr={2} color="cyan.200" leftIcon={<FiEye size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading} isLoading={openPostsFilterLoading} onClick={updateOpenFilter} opacity={globalData.profileSettings.openPosts[username] ? 1 : 0.5}>Open</Button>
                        </Flex>
                    </Flex>
                </Box>
                {
                    globalData.allPosts[username].length === 0 ? ( //globalData.allPosts.filter(item => item.pf.username === username).length
                        <Flex bg='#212121' mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} py={5} mb={5} borderWidth={1} borderRadius={10}>
                            <Text as='b'>No posts from '{username}'</Text>
                        </Flex>

                    ) : (
                            <>
                                <InfiniteScroll
                                    scrollableTarget={"scrollableDiv"}
                                    dataLength={globalData.allPosts[username].length}
                                    onScroll={(e) => {
                                        window.history.replaceState({}, '', `${location.pathname}?focus=${listRef.current.getScrollPosition().index}&offset=${listRef.current.getScrollPosition().offset}`)
                                    }}
                                    hasMore={false}
                                    loader={<Flex mt={5} mb={5} justify="center"> <Spinner thickness='4px' speed='1s' size='md' color='#949FFA' /> </Flex>}>
                                    {
                                        <ViewportList ref={listRef} viewportRef={refM} items={globalData.allPosts[username] || []} initialPrerender={10} initialIndex={initIndex} initialOffset={offset} >
                                            {(item, index) => (
                                                <Posts indexCount={index} key={item.post_id} postItem={item} />
                                            )}
                                        </ViewportList>
                                    }
                                </InfiniteScroll>
                                {
                                    morePosts ? <Center mb={3} mt={2}>
                                        <Button borderRadius={10} isLoading={morePostsLoading} size='sm' width="30%" onClick={getNextPosts}>Load More</Button>
                                    </Center> : null
                                }
                            </>
                        )
                }

            </List>

        </Flex>


    )

}

export default ProfileMain